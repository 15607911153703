/* You can add global styles to this file, and also import other style files */
@use '@angular/cdk';

@include cdk.a11y-visually-hidden();
@import "@ng-select/ng-select/themes/default.theme.css";

// Media Query

@mixin media($breakpoint) {
  // $breakpoint is simply a variable that can have several values

  // min-width

  @if $breakpoint==min-xl {
    @media only screen and (min-width: 1440px) {
      @content;
    }
  }

  @if $breakpoint==min-lg {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint==min-md {
    @media only screen and (min-width: 991px) {
      @content;
    }
  }

  @if $breakpoint==min-sm {
    @media only screen and (min-width: 767px) {
      @content;
    }
  }

  // max-width
  @if $breakpoint==xl {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }

  @if $breakpoint==lg {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint==md {
    @media only screen and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint==sm {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint==xs {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }
}

body {
  font-family: "Work Sans", sans-serif !important;
  background: #f5f5f5 !important;
}

a {
  text-decoration: none !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-success {
  color: #4d6b7e !important;
}

.text-green {
  color: #198754 !important;
}

.text-red {
  color: #dc3545 !important;
}

.text-gray {
  color: #9b9696 !important;
}

.font-14 {
  font-size: 14px !important;
}

.btn {
  padding: 6px 30px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 0.5rem;

  &.btn-success {
    border: 1px solid #4d6b7e;
    background: #4d6b7e;

    &:hover,
    &:focus {
      border: 1px solid #dae02f;
      background: #dae02f;
    }
  }

  &.btn-green {
    color: #fff;
    border: 1px solid #198754;
    background: #198754;

    &:hover,
    &:focus {
      border: 1px solid #dae02f;
      background: #dae02f;
    }
  }

  &.btn-outline-success {
    color: #4d6b7e;
    border: 1px solid #4d6b7e;

    &:hover,
    &:focus {
      color: #fff;
      border: 1px solid #4d6b7e;
      background: #4d6b7e;
    }
  }

  &.btn-secondary {
    border: 1px solid #6e7071;
    background: #6e7071;

    &:hover,
    &:focus {
      border: 1px solid #525050;
      background: #525050;
    }
  }

  &.btn-info {
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  &.btn-outline-info {
    &:hover {
      color: #fff;
    }
  }
}

.form-label {
  font-weight: 500;
  color: #3d4465;
  font-size: 0.938rem;
  margin-bottom: 4px;
}

.form-control,
.form-select {
  border-radius: 0.5rem;
  border: 0.0625rem solid #e6e6e6;
  padding: 0.3125rem 1rem;
  color: #6e6e6e;
  height: 2.8rem;
  font-size: 16px;

  &:focus {
    border: 1px solid #4d6b7e;
    box-shadow: none;
  }

  &::placeholder {
    font-size: 14px;
  }
}

.auth-section {
  min-height: 100vh;
  display: flex;
  padding: 30px 0;
  align-items: center;

  .card {
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0rem solid transparent;
    border-radius: 0.75rem;
    box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);

    .card-body {
      padding: 1.875rem;
    }
  }

  .code-input {
    input {
      height: 70px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

/* Importing Bootstrap SCSS file. */
// @import "bootstrap/scss/bootstrap";

.main-content {
  padding: 110px 15px 20px 195px;
  transition: all 0.2s ease;
  min-height: calc(100vh - 40px);

  @include media(md) {
    padding: 90px 0 20px 0;
  }
}

.breadcrumb {
  background: #fff;
  padding: 10px 25px;
  border-radius: 8px;
  margin-bottom: 25px;

  @include media(md) {
    margin-bottom: 20px;
  }

  .breadcrumb-item {
    a {
      color: #828690;
    }

    &.active {
      color: #4d6b7e !important;
      font-weight: 500;
    }
  }
}

.card {
  margin-bottom: 1rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  height: calc(100% - 1.875rem);

  .card-header {
    border-color: #e6e6e6;
    position: relative;
    background: transparent;
    padding: 16px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4d6b7e;
    font-size: 22px;

    @include media(md) {
      padding: 14px 20px;
      font-size: 18px;
    }
  }

  .card-body {
    padding: 30px;

    @include media(md) {
      padding: 20px;
    }

    h5 {
      font-size: 18px;
    }

    h6 {
      font-size: 16px;
    }

    hr {
      border-top: 1px solid #a39c9c;
    }

    .bi-info-circle {
      color: #4d6b7e;
      font-size: 18px;
    }

    .form-control {
      border-radius: 8px;

      &::placeholder {
        color: #b2acac;
      }
    }

    select {
      color: #b2acac;
    }

    textarea {
      height: 80px;
    }
  }
}

.sidebar {
  position: fixed;
  width: 180px !important;
  top: 0;
  padding: 20px 0;
  z-index: 9999;
  background-color: #fff;
  transition: all 0.2s ease;
  border: 0 !important;
  box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02);
  height: 100%;

  @include media(lg) {
    box-shadow: 0rem 0.9375rem 1.875rem 0rem rgb(0 0 0 / 11%);
  }

  @include media(min-md) {
    transform: none !important;
    visibility: visible;
  }

  .btn-close {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 9;
    font-size: 13px;
  }

  .accordion {
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 120px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4d6b7e;
    }

    .accordion-item {
      border: 0;

      .accordion-button:not(.collapsed) {
        background-color: transparent;
      }

      .accordion-button.active {
        // background: rgb(218 224 50 / 15%);
        background: linear-gradient(to right,
            #e0e0e4 0%,
            rgba(125, 185, 232, 0) 100%);
        box-shadow: none;
        color: #4d6b7e;
        font-weight: 600;

        &::before {
          width: 0.5rem;
        }
      }

      .accordion-button {
        flex-direction: column;

        &:focus {
          box-shadow: none;
        }
      }

      .accordion-body {
        padding: 10px 0;
      }

      h2 {
        button {
          padding: 15px 10px;
          font-size: 14px;
          font-weight: 500;
          color: #737b8b;

          &:after {
            display: none !important;
          }

          &::before {
            content: "";
            height: 4.1rem;
            width: 0;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translatey(-50%);
            background: #4d6b7e;
            border-radius: 0 0.75rem 0.75rem 0;
            -webkit-transition: all 0.5s;
            -ms-transition: all 0.5s;
            transition: all 0.5s;
          }

          &:hover {
            &::before {
              width: 0.5rem;
            }
          }

          i {
            font-size: 30px;
            position: relative;
            color: #dae032;
            margin-bottom: 10px;
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        li {
          a {
            color: #737b8b;
            font-size: 14px;
            font-weight: 400;
            display: block;
            padding: 6px 0;

            &:hover {
              color: #4d6b7e;
              font-weight: 500;
            }

            &.active {
              font-weight: 500;
              background: linear-gradient(to right,
                  #e0e0e4 0%,
                  rgba(125, 185, 232, 0) 100%);
            }
          }
        }
      }
    }
  }
}

// Header

header {
  .navbar {
    background: #fff;
    padding: 18px 15px 18px 195px;

    @include media(md) {
      padding: 10px 10px;
    }

    .bi-list {
      font-size: 20px;
    }

    form {
      position: relative;

      .form-control {
        border-radius: 30px;
        background: #f5f5f5cc;
        border: 1px solid #f5f5f5cc;
        padding: 5px 20px;
        min-width: 300px;
        height: 42px;
      }

      .btn {
        position: absolute;
        right: 0px;
        top: 1px;
        color: #4d6b7e;
      }
    }

    .navbar-nav {
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 12px;
      }

      h6 {
        color: #4d6b7e;
      }

      small {
        color: #7a7c81;
      }

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-menu {
        border: 0;
        box-shadow: 0 2px 10px #00000014;
        border-radius: 5px;
        position: absolute;

        a {
          font-size: 14px;

          &:hover {
            color: #4d6b7e;
          }

          &:active {
            background-color: transparent !important;
          }

          i {
            font-size: 18px;
            margin-right: 2px;
          }
        }
      }
    }
  }
}

// Footer

footer {
  padding-left: 250px;

  @include media(md) {
    padding-left: 0;
  }

  p {
    font-size: 14px;
    color: #9fa4a6;
    text-align: center;

    a {
      color: #4d6b7e;
      font-weight: 600;
    }
  }
}

body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #4d6b7e;
}

.mat-stepper-horizontal {
  margin-top: 8px;
}

.mat-mdc-form-field {
  margin-top: 16px;
}

.mat-horizontal-content-container {
  padding: 24px 24px 5px !important;

  @include media(sm) {
    padding: 24px 15px 5px !important;
  }
}

.mdc-button {
  border-radius: 5px !important;
  color: #fff !important;
  padding: 6px 15px !important;
}

.mat-step-icon {
  background: #edebeb !important;
}

.mat-step-icon-selected {
  color: #fff !important;
  background: #4d6b7e !important;
}

.form-check-input:checked {
  background-color: #4d6b7e;
  border-color: #4d6b7e;
}

.form-switch .form-check-input:checked {
  box-shadow: none;
}

.mat-step-label-selected {
  .mat-step-text-label {
    font-weight: 600;
    color: #4d6b7e !important;
  }
}

.mat-stepper-horizontal-line {
  border-top-color: #dbd6d6 !important;
}

.custom-tooltip .tooltip-inner {
  background-color: #4d6b7e;
}

.custom-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #4d6b7e;
}

.custom-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #4d6b7e;
}

.custom-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #4d6b7e;
}

.custom-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #4d6b7e;
}

// Table

.table {
  text-align: center;

  tr {
    th {
      font-weight: 600;
      font-size: 0.875rem;
      background: #4d6b7e2e;
      color: #4d6b7e;
      padding: 10px 20px;
      border-right: 1px solid #fff;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        border-right: 1px solid #dee2e6;
      }
    }

    td {
      padding: 10px 20px;
      vertical-align: middle;
      font-size: 0.875rem;

      &:first-child {
        text-align: left;
      }
    }
  }

  .btn {
    font-size: 14px;
  }
}

.badge {
  border-radius: 0.375rem;
  font-weight: 500;
  padding: 7px 12px;
  font-size: 12px;
  min-width: 80px;

  &.text-bg-success {
    background-color: #d5f5dc !important;
    color: #37d159 !important;
  }

  &.text-bg-neutral {
    background-color: #ececec !important;
    color: #919191 !important;
  }

  &.text-bg-info {
    background-color: #0dcaf030 !important;
    color: #0dcaf0 !important;
  }

  &.text-bg-warning {
    background-color: #ffc1073d !important;
    color: #ffc107 !important;
  }

  &.text-bg-danger {
    background-color: #ffe5df !important;
    color: #ff6746 !important;
  }
}

.add-new-site {
  .mat-horizontal-stepper-header-container {
    max-width: 500px;
  }
}

.nav-tabs {
  margin-bottom: 25px;

  .nav-link {
    color: #939191;
    font-weight: 500;

    &.active {
      color: #4d6b7e;
      font-weight: 600;

      i {
        color: #dae032;
      }
    }

    i {
      color: #dae032 !important;
      font-size: 20px !important;
      margin-right: 5px;
    }
  }

  li {
    @include media(sm) {
      border: 1px solid #e1dfdf;
      width: 50%;
      border-bottom: 0;
    }

    @include media(xs) {
      width: 100%;
    }
  }

  .nav-link {
    @include media(md) {
      width: 100%;
      border-radius: 0;

      &.active {
        border: 0;
        background: #4d6b7e12;
      }
    }
  }
}

.modal {
  .modal-content {
    border: 0;
  }

  .modal-header {
    background: #4d6b7e;
    padding: 10px 15px;

    &.danger {
      background: #dc3545;
    }

    h1 {
      color: #fff;
      font-size: 24px;
    }

    .btn-close {
      background: none;
      font-size: 24px;
      color: #fff;
      position: relative;
      top: -6px;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.pagination {
  .page-link {
    color: #4d6b7e;
    border-radius: 50% !important;
    width: 35px;
    height: 35px;
    margin: 0 8px;
    text-align: center;
    font-size: 15px;
  }

  .active {
    .page-link {
      color: #fff;
      border: 1px solid #4d6b7e;
      background: #4d6b7e;
    }
  }
}

.nav-pills {
  .nav-link {
    color: #4d6b7e;

    &.active {
      background: #4d6b7e;
    }
  }
}

.fc-scrollgrid .fc-col-header a {
  color: #4d6b7e;
  font-weight: 600 !important;
  font-family: "Work Sans", sans-serif !important;
}

.fc-scrollgrid .fc-daygrid-body a {
  color: #888;
}

.fc-h-event .fc-event-title-container {
  padding: 3px 8px;
  text-align: center;
}

.fc-scrollgrid thead tr th {
  background: #f5f5f5;
}

.fc-button-group .fc-button-primary,
.fc-button-group .fc-button-primary:hover {
  color: #10dfd7 !important;
  background: #f5f5f5 !important;
  border-color: #b7bbc0;
}

.fc-button-group .fc-button-primary:not(:disabled).fc-button-active,
.fc-today-button {
  color: #fff !important;
  background: #10dfd7 !important;
  border: 1px solid #10dfd7;
}

.fc .fc-button {
  padding: 0.2em 0.65em;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
  outline: none;
}

.fc-toolbar-title {
  font-size: 16px !important;
}


.mat-horizontal-stepper-header {
  @include media(md) {
    padding: 0 10px !important;
  }
}

.table-responsive {
  .table {
    @include media(md) {
      width: 800px;
    }
  }

  &.table-large {
    .table {
      @include media(lg) {
        width: 1100px;
      }
    }
  }
}

.fc .fc-toolbar {
  @include media(sm) {
    flex-direction: column;
    gap: 20px;
  }
}

// NGX Datatable

ngx-datatable.bootstrap {
  border: 1px solid #dee2e6;
}

/* Header Styling */
ngx-datatable.bootstrap .datatable-header {
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
  font-size: 0.875rem;
  background: rgba(77, 107, 126, 0.1803921569);
  color: #4d6b7e;
  height: 43px !important;
}

ngx-datatable.bootstrap .datatable-header-cell {
  padding: 10px 20px;
  text-align: left;
  border-right: 1px solid #fff;
}

/* Row Styling */
ngx-datatable.bootstrap .datatable-body-row {
  border-bottom: 1px solid #ddd;
}

ngx-datatable.bootstrap .datatable-body-cell {
  padding: 10px 20px;
  border-right: 1px solid #dee2e6;
  display: flex;
  align-items: center;
}

/* Alternate Row Color */
ngx-datatable.bootstrap .datatable-body-row:nth-child(even) {
  background-color: #fafafa;
}

/* Hover Effect */
ngx-datatable.bootstrap .datatable-body-row:hover {
  background-color: #e9ecef;
}

ngx-datatable.bootstrap .datatable-body-row:hover .note-content:before {
  background:linear-gradient(transparent 20px, #e9ecef);
}


/* Pagination Styling */
ngx-datatable.bootstrap .datatable-footer {
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  padding: 6px 15px;
  margin-top: -7px;
}

ngx-datatable.bootstrap .datatable-footer .page-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ngx-datatable.bootstrap .datatable-footer .datatable-pager .pager li {
  margin: 0 3px;
}

ngx-datatable.bootstrap .datatable-footer .datatable-pager .pager a {
  width: 30px;
  height: 30px;
  color: #585555;
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  padding: 4px 0;

  i {
    position: relative;
    top: 2px;
  }
}



ngx-datatable.bootstrap .datatable-footer .datatable-pager .pager a:hover,
ngx-datatable.bootstrap .datatable-footer .datatable-pager .pager li.active a {
  background-color: #4d6b7e;
  color: #fff;
}

ngx-datatable.bootstrap .datatable-footer .datatable-footer-inner {
  height: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

// Forms
.ng-invalid.ng-touched {
  border-color: red;
}
.ng-invalid.ng-touched > .ng-select-container {
  border-color: red !important;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner>div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #eee;
  border-top-color: #dae032;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.modal-dialog {
  margin: 0 auto;
}

a,
button {
  cursor: pointer;
}

.btn-link-small {
  font-size: 14px;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.ng-select-container {
  min-height: 2.8rem !important;
  border: 0.0625rem solid #e6e6e6 !important;
  border-radius: 8px !important;

  .ng-input {
    top: unset !important;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 9px !important;
}

.empty-row {
  width: 100%;
  text-align: center;
  padding: 50px;
  opacity: 0.5;
  letter-spacing: 1px;
}

ngx-datatable[ng-reflect-count="0"] {

  .datatable-footer {
    display: none;
  }
}
